<template>
  <div>
    <el-card class="lg" shadow="hover">
      <div class="title lg">{{$t('property.my_property')}}</div>
      <div class="sub-title lg">{{$t('property.property_total')}} : <span>{{((userInfo.asset_balance+userInfo.deposit+userInfo.fuel_card)*1) | roundTwoFilter}} USDT</span><span> ≈ ¥{{((userInfo.asset_balance+userInfo.deposit+userInfo.fuel_card)*1*rate) | roundTwoFilter}}</span></div>
      <div class="sm">
        <div style="padding: 24px 0;background: #FFFFFF;">
          <div style="color: #72777E;font-size: 16px">{{$t('property.property_total')}} :</div>
          <div style="font-size: 24px;color: #000613;margin: 8px 0">{{((userInfo.asset_balance+userInfo.deposit+userInfo.fuel_card)*1) | roundTwoFilter}} USDT</div>
          <div style="color: #72777E;font-size: 16px">≈ ¥{{((userInfo.asset_balance+userInfo.deposit+userInfo.fuel_card)*1*rate) | roundTwoFilter}}</div>
        </div>
      </div>
      <div class="property">
        <div class="echarts lg" id="propertyEcharts"></div>
        <div>
          <div><span style="background: #5587EE;"></span><span>{{$t('property.capital_account')}}</span></div>
          <div>{{userInfo.asset_balance | roundTwoFilter}} USDT</div>
          <div>≈ ¥{{userInfo.asset_balance*rate | roundTwoFilter}}</div>
          <div class="btns">
<!--            <span class="top-up-btn" @click="getTopUpInfo">充值</span>-->
            <span class="top-up-btn" @click="getTopUpInfo">{{$t('property.top_up')}}</span>
            <span v-if="userInfo.tixian_state==2" style="margin-left: 10px;margin-right:0" @click="showTixian">{{$t('property.withdraw')}}</span>
            <span v-if="userInfo.tixian_state==1" style="margin-left: 10px;margin-right:0;cursor: not-allowed;background: rgba(44, 117, 255, 0.15);color: rgb(114, 119, 126);border: 1px solid rgb(207, 217, 236)">{{$t('property.withdraw1')}}</span>
            <span v-if="userInfo.is_his==0" style="margin-left: 10px;" @click="handleTransfer(1)">{{$t('property.transfer')}}</span>
          </div>
        </div>
        <div v-if="userInfo.is_his==0">
          <div><span style="background: #15D89C;"></span><span>{{$t('property.trading_account')}}</span><img style="vertical-align: middle;margin-left: 5px" v-if="userInfo.charge_style2 == 2 || userInfo.charge_style2 == 3" src="../../assets/img/user/discount.svg"></div>
          <div>{{userInfo.deposit | roundTwoFilter}} USDT</div>
          <div>≈ ¥{{userInfo.deposit*rate | roundTwoFilter}}</div>
          <div class="btns">
<!--            <span class="top-up-btn" @click="getTopUpInfo">{{$t('property.top_up')}}</span>-->
            <span @click="handleTransfer(2)">{{$t('property.transfer')}}</span>
          </div>
        </div>
        <div>
          <div><span style="background: #F4CE57"></span><span>{{$t('property.rebate_account')}}</span></div>
          <div>{{userInfo.fuel_card | roundTwoFilter}} USDT</div>
          <div>≈ ¥{{(userInfo.fuel_card*rate) | roundTwoFilter}}</div>
          <div class="btns">
            <span @click="topUpCardVisible = true">购买</span>
          </div>
        </div>
      </div>
    </el-card>

    <el-card class="sm" shadow="hover" style="margin-bottom: 12px">
      <div class="sm">
        <div style="padding: 0;background: #FFFFFF;">
          <div style="color: #72777E;font-size: 16px">{{$t('property.property_total')}} :</div>
          <div style="font-size: 24px;font-weight: bold;color: #000613;margin: 8px 0">{{((userInfo.asset_balance+userInfo.deposit+userInfo.fuel_card)*1) | roundTwoFilter}} USDT</div>
          <div style="color: #72777E;font-size: 16px">≈ ¥{{((userInfo.asset_balance+userInfo.deposit+userInfo.fuel_card)*1*rate) | roundTwoFilter}}</div>
        </div>
        <div class="btns">
          <span class="top-up-btn" @click="getTopUpInfo">{{$t('property.top_up')}}</span>
          <span v-if="userInfo.tixian_state==2" style="margin-left: 10px;margin-right:0" @click="showTixian">{{$t('property.withdraw')}}</span>
          <span v-if="userInfo.tixian_state==1" style="margin-left: 10px;margin-right:0;cursor: not-allowed;background: rgba(44, 117, 255, 0.15);color: rgb(114, 119, 126);border: 1px solid rgb(207, 217, 236)">{{$t('property.withdraw1')}}</span>
          <span v-if="userInfo.is_his==0" style="margin-left: 10px;" @click="handleTransfer(1)">{{$t('property.transfer')}}</span>
        </div>
      </div>
    </el-card>

    <el-card class="sm" shadow="hover" style="margin-bottom: 12px">
      <div style="border-bottom: 1px solid #EAECEF;padding-bottom: 10px">
        <div>
          <span style="display:inline-block;vertical-align:middle;margin-right:5px;background: #5587EE;width: 6px;height: 6px;"></span>
          <span style="display: inline-block;vertical-align: middle;color:#72777E;font-size: 16px">{{$t('property.capital_account')}}</span>
        </div>
        <div style="color: #000613;margin-top: 8px;font-weight: bold;font-size: 22px;font-family: DINPro-Bold, DINPro;">{{userInfo.asset_balance | roundTwoFilter}} USDT</div>
        <div style="font-size: 16px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #72777E;">≈ ¥{{userInfo.asset_balance*rate | roundTwoFilter}}</div>
      </div>

      <div v-if="userInfo.is_his==0" style="border-bottom: 1px solid #EAECEF;padding: 10px 0">
        <div>
          <span style="display:inline-block;vertical-align:middle;margin-right:5px;background: #15D89C;width: 6px;height: 6px;"></span>
          <span style="display: inline-block;vertical-align: middle;color:#72777E;font-size: 16px">{{$t('property.trading_account')}}</span><img style="vertical-align: middle;margin-left: 5px" v-if="userInfo.charge_style2 == 2 || userInfo.charge_style2 == 3" src="../../assets/img/user/discount.svg"></div>
        <div style="color: #000613;margin-top: 8px;font-weight: bold;font-size: 22px;font-family: DINPro-Bold, DINPro;">{{userInfo.deposit | roundTwoFilter}} USDT</div>
        <div style="font-size: 16px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #72777E;">≈ ¥{{userInfo.deposit*rate | roundTwoFilter}}</div>
      </div>

      <div style="padding: 10px 0 0;position: relative">
        <div>
          <span style="display:inline-block;vertical-align:middle;margin-right:5px;background: #F4CE57;width: 6px;height: 6px;"></span>
          <span style="display: inline-block;vertical-align: middle;color:#72777E;font-size: 16px">{{$t('property.rebate_account')}}</span>
        </div>
        <div style="color: #000613;margin-top: 8px;font-weight: bold;font-size: 22px;font-family: DINPro-Bold, DINPro;">{{userInfo.fuel_card | roundTwoFilter}} USDT</div>
        <div style="font-size: 16px;font-family: DINPro-Medium, DINPro;font-weight: 500;color: #72777E;">≈ ¥{{(userInfo.fuel_card*rate) | roundTwoFilter}}</div>
        <span style="position: absolute;right: 0px; bottom: 0px; color: #AB54DB" @click="topUpCardVisible = true">购买</span>
      </div>

    </el-card>

<!--    <div class="sm">-->
<!--      <div style="padding: 24px 16px;background: #FFFFFF;">-->
<!--        <div style="color: #72777E;font-size: 16px">資產總折合 :</div>-->
<!--        <div style="font-size: 24px;color: #000613;margin: 8px 0">{{((userInfo.asset_balance+userInfo.deposit+userInfo.commission_balance)*1) }} USDT</div>-->
<!--        <div style="color: #72777E;font-size: 16px">≈ ¥{{((userInfo.asset_balance+userInfo.deposit+userInfo.commission_balance)*1*rate) }}</div>-->
<!--        <div class="btns">-->
<!--          <span @click="getTopUpInfo">充值</span>-->
<!--          <span @click="withdrawDialogVisible=true">提現</span>-->
<!--          <span @click="handleTransfer(1)">劃轉</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <el-card style="margin-top: 12px">-->
<!--        <div class="sm-icons">-->
<!--          <div><span style="background: #5587EE;"></span><span>資金賬戶</span></div>-->
<!--          <div>{{userInfo.asset_balance | roundTwoFilter}} USDT</div>-->
<!--          <div>≈ ¥{{userInfo.asset_balance*rate | roundTwoFilter}}</div>-->
<!--        </div>-->
<!--      </el-card>-->
<!--      <el-card>-->
<!--        <div class="sm-icons">-->
<!--          <div><span style="background: #15D89C;"></span><span>交易賬戶</span></div>-->
<!--          <div>{{userInfo.deposit | roundTwoFilter}} USDT</div>-->
<!--          <div>≈ ¥{{(userInfo.deposit*rate) | roundTwoFilter}}</div>-->
<!--        </div>-->
<!--      </el-card>-->
<!--      <div style="padding: 24px 16px;background: #FFFFFF;margin-bottom: 12px">-->
<!--        <div class="sm-icons">-->
<!--          <div><span style="background: #F4CE57;"></span><span>返傭獎金</span></div>-->
<!--          <div>{{userInfo.commission_balance | roundTwoFilter}} USDT</div>-->
<!--          <div>≈ ¥{{(userInfo.commission_balance*rate) | roundTwoFilter}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <el-card shadow="hover" style="position: relative">
      <div style="position: absolute;top: 29px;left: 180px;z-index: 100">
        <tooltip id="lg-tip" :content="$t('property.tip')"></tooltip>
        <img style="margin-top: 2px" @click="handleShowDialogTip" id="sm-tip" src="../../assets/img/robot/robot_strategy/explain.svg" alt="">
      </div>
      <el-tabs  v-model="activeScheduleName" @tab-click="handleChangeSchedule" >
        <el-tab-pane :label="$t('property.top_up_order')" name="1"></el-tab-pane>
        <el-tab-pane :label="$t('property.withdraw_order')" name="2"></el-tab-pane>
      </el-tabs>
      <div class="Schedule-table">
        <el-table
            :size="isMini"
            :data="scheduleList"
            style="width: 100%">
          <el-table-column
              :label="$t('property.time')"
              min-width="152px">
            <template slot-scope="scope">
              {{ activeScheduleName==1 ? scope.row.time_ok :scope.row.time_req  }}
            </template>
          </el-table-column>
          <el-table-column
              min-width="70px"
              :label="$t('property.type')">
            <template slot-scope="scope">
              {{ activeScheduleName==1 ? $t('property.top_up') :$t('property.withdraw')  }}
            </template>
          </el-table-column>
          <el-table-column
              min-width="80px"
              :label="$t('property.account')">
            <span>{{$t('property.capital_account')}}</span>
          </el-table-column>
          <el-table-column
              min-width="70px"
              :label="$t('property.kind')">
            <span>USDT</span>
          </el-table-column>
          <el-table-column
              prop="chongzhi_money"
              min-width="70px"
              :label="$t('property.quantity')">
            <template slot-scope="scope">
              {{ activeScheduleName==1 ? scope.row.chongzhi_money : scope.row.cash_money  }}
            </template>
          </el-table-column>
          <el-table-column
              min-width="300px"
              :label="$t('property.address')">
            <template slot-scope="scope">
              {{ activeScheduleName==1 ? scope.row.chongzhi_url : scope.row.cash_address  }}
            </template>
          </el-table-column>
          <el-table-column
              min-width="80px"
              :label="$t('property.schedule')">
            <template slot-scope="scope">
              <span v-if="activeScheduleName==1">1/1</span>
              <span v-else>
                <span v-if="scope.row.state==null">0/1</span>
                <span v-else-if="scope.row.state==2">1/1</span>
                <span v-else>-</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
              min-width="80px"
              :label="$t('property.state')">
            <template slot-scope="scope">
              <span v-if="activeScheduleName==1">{{$t('property.top_up_success')}}</span>
              <span v-else>
                <span v-if="scope.row.state==null || scope.row.state==1">{{$t('property.underway')}}</span>
                <span v-else-if="scope.row.state==2">{{$t('property.withdraw_success')}}</span>
                <span v-else>{{$t('property.repeal_withdraw')}}</span>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            style="text-align: right;margin: 24px"
            layout="prev, pager, next"
            @current-change="handleScheduleCurrentChange"
            :page-size="10"
            :hide-on-single-page="scheduleListTotal<=10"
            :total="scheduleListTotal">
        </el-pagination>
      </div>
    </el-card>
    <el-card shadow="hover">
      <el-tabs  v-model="activeRecordName" @tab-click="handleChangeRecord">
<!--        <el-tab-pane :label="$t('property.capital_account')+$t('property.record')" name="1"></el-tab-pane>-->
        <el-tab-pane :label="$t('property.trading_account_card')+$t('property.record')" name="2"></el-tab-pane>
        <el-tab-pane :label="$t('property.rebate_account')+$t('property.record')" name="3"></el-tab-pane>
      </el-tabs>
<!--      資金賬戶記錄-->
      <div v-if="activeRecordName==1" class="record-table">
        <el-table
            :size="isMini"
            :data="recordList"
            style="width: 100%">
          <el-table-column
              prop="create_time"
              :label="$t('property.record_time')"
              min-width="152px">
          </el-table-column>
          <el-table-column
              prop="type"
              min-width="70px"
              :label="$t('property.account_type')">
            <template slot-scope="scope">
              <span v-if="scope.row.type==1">{{$t('property.top_up')}}</span>
              <span v-else-if="scope.row.type==2">{{$t('property.withdraw')}}</span>
              <span v-else-if="scope.row.type==3">{{$t('property.transfer')}}</span>
              <span v-else-if="scope.row.type==5">{{$t('property.fee')}}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="coin"
              min-width="70px"
              :label="$t('property.kind')">
          </el-table-column>
          <el-table-column
              prop="money"
              min-width="100px"
              :label="$t('property.property_change')">
          </el-table-column>
          <el-table-column
              prop="to_address"
              min-width="100px"
              :label="$t('property.change_address')">
            <template slot-scope="scope">
              <span v-if="scope.row.to_address==1">{{$t('property.capital_account')}}</span>
              <span v-else-if="scope.row.to_address==2">{{$t('property.trading_account')}}</span>
              <span v-else-if="scope.row.to_address==3">{{$t('property.rebate_account')}}</span>
              <span v-else>{{scope.row.to_address}}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="to_balance"
              min-width="100px"
              :label="$t('property.account_balance')+'(USDT)'">
          </el-table-column>
        </el-table>
        <el-pagination
            style="text-align: right;margin: 24px"
            layout="prev, pager, next"
            @current-change="handleRecordCurrentChange"
            :page-size="10"
            :hide-on-single-page="recordListTotal<=10"
            :total="recordListTotal">
        </el-pagination>
      </div>
<!--      交易記錄-->
      <div v-if="activeRecordName==2" class="record-table">
        <el-table
            :size="isMini"
            :data="recordList"
            style="width: 100%">
          <el-table-column
              prop="time_delete"
              :label="$t('property.record_time')"
              min-width="152px">
          </el-table-column>
          <el-table-column
              min-width="80px"
              :label="$t('property.account_type')">
            <template slot-scope="scope">
              <span>{{ scope.row.fuel_card_balance?$t('property.card_fee'):$t('property.user_fee')}}</span>
            </template>
          </el-table-column>
          <el-table-column
              min-width="70px"
              :label="$t('user_center.earn')+'(USDT)'">
            <template slot-scope="scope">
              <span>{{ (scope.row.profit*1).toFixed(6)}}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="deposit_change"
              min-width="144px"
              :label="$t('property.deduct')+'(USDT)'">
          </el-table-column>
          <el-table-column
              min-width="100px"
              prop="transactionType"
              :label="$t('property.transaction')">
          </el-table-column>
          <el-table-column
              min-width="120px"
              :label="$t('transfer.balance')+'(USDT)'">
            <template slot-scope="scope">
              <span>{{ scope.row.fuel_card_balance?scope.row.fuel_card_balance:scope.row.deposit_balance}}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            style="text-align: right;margin: 24px"
            layout="prev, pager, next"
            @current-change="handleRecordCurrentChange"
            :page-size="10"
            :hide-on-single-page="recordListTotal<=10"
            :total="recordListTotal">
        </el-pagination>
      </div>
<!--      点卡记录-->
      <div v-if="activeRecordName==3" class="record-table">
        <el-table
            :size="isMini"
            :data="pointCardList"
            style="width: 100%">
          <el-table-column
              prop="create_time"
              :label="$t('property.record_time')"
              min-width="152px">
          </el-table-column>
          <el-table-column
              prop="content"
              min-width="154px"
              :label="$t('property.content')">
          </el-table-column>
          <el-table-column
              prop="card_amount"
              min-width="144px"
              :label="$t('property.award_money')+'(USDT)'">
          </el-table-column>
<!--          <el-table-column-->
<!--              prop="give_invitation"-->
<!--              min-width="80px"-->
<!--              :label="$t('property.invite_code')">-->
<!--          </el-table-column>-->
        </el-table>
<!--        <el-pagination-->
<!--            style="text-align: right;margin: 24px"-->
<!--            layout="prev, pager, next"-->
<!--            @current-change="handleRecordCurrentChange"-->
<!--            :page-size="10"-->
<!--            :hide-on-single-page="recordListTotal<=10"-->
<!--            :total="recordListTotal">-->
<!--        </el-pagination>-->
      </div>
    </el-card>
    <transfer @updateUserInfo="getUserInfo" @renderPropertyEcharts="renderPropertyEcharts" ref="transfer"></transfer>


    <!--    充值对话框-->
    <el-dialog
        :visible.sync="topUpDialogVisible"
        width="510px"
        title="资产充值"
        class="topUpDialog"
        :close-on-click-modal="false"
        height="304px">
      <div class="topUp">
        <div>主网</div>
        <div><el-input disabled placeholder="TRC20"></el-input></div>
        <div>钱包地址（此地址只可接收USDT）</div>
        <div><img :src="topUpInfo.qrCode" alt="" style="max-width: 130px"></div>
        <div>
          <span>{{topUpInfo.base58Address}}</span><img @click="handleCopyAddress" style="cursor: pointer" src="../../assets/img/rebate/copy.svg" alt="">
        </div>
        <div>重要提示：</div>
        <div>
          <ul>
            <li>最少充值金额为50USDT</li>
            <li>请仔细确认地址，充错无法找回</li>
          </ul>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%"  @click="topUpDialogVisible=false">确定</el-button>
      </span>
    </el-dialog>

    <!--提现对话框-->
    <el-dialog
        :visible.sync="withdrawDialogVisible"
        width="510px"
        :title="$t('rebate.property_withdraw')"
        class="topUpDialog"
        :close-on-click-modal="false"
        height="304px">
      <el-form :model="withdrawForm" :rules="withdrawFormRules" ref="withdrawFormRef">
        <el-form-item :label="$t('rebate.withdraw_money')" prop="money" style="position: relative">
          <el-input @input="checkinput($event)" v-model="withdrawForm.money"></el-input>
          <div style="font-size: 14px;color: #B0B5BA;position: absolute;top: 41px;right: 66px">USDT</div>
          <div style=" width:1px;height:20px;position: absolute;top: 50px;right: 54px;border-right:1px solid #EAECEF;"></div>
          <div @click="handleMoneyAll"  style="font-size: 14px;font-weight: 400;color: #AB54DB;position: absolute;top: 40px;right: 12px;cursor: pointer">{{$t('rebate.all')}}</div>
        </el-form-item>
        <el-form-item :label="$t('rebate.withdraw_address')" prop="address"><span style="color: #FF4D4F; margin-left: -10px">{{$t('rebate.TRC20_address')}}</span>
          <el-input v-model="withdrawForm.address"></el-input>
        </el-form-item>
        <!--        <div  style="font-size: 12px;margin-top: 20px">重要提示:</div>-->
        <!--        <div>-->
        <!--          <ul style="padding-left: 20px;margin: 5px 0">-->
        <!--            <li>提现手续费为1USDT，从提现金额里直接扣除</li>-->
        <!--          </ul>-->
        <!--        </div>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%"  @click="handleWithdraw">{{$t('rebate.submit_apply')}}</el-button>
      </span>
    </el-dialog>
    <!--    提現確認對話框   -->
    <el-dialog
        width="510px"
        :visible.sync="confirmWithdrawDialogVisible"
        :title="$t('rebate.property_withdraw_confirm')"
        class="topUpDialog"
        :close-on-click-modal="false"
        height="304px">
      <div style="margin-top: 20px">{{$t('rebate.withdraw_money')}} : {{withdrawForm.money}}USDT</div>
      <div style="margin: 12px 0 0px 0">{{$t('rebate.withdraw_address')}}: {{withdrawForm.address}}</div>
      <el-form class="withdraw_input" :model="withdrawForm" :rules="withdrawFormRules" ref="withdrawFormRef">
        <el-form-item :label="$t('rebate.email_auth_code')" prop="phone_auth_code" style="position: relative">
          <el-input v-model="withdrawForm.phone_auth_code"></el-input>
          <div v-if="userInfo.username!=null&&userInfo.username!=''" style="position: absolute;left: 83px;top: 0;">({{userInfo.username.slice(0,3)}} <span style="vertical-align: middle">****</span> {{userInfo.username.slice(7)}})</div>
          <div class="auth-code">
            <div v-if="isPhoneSent" @click="sendAuthCode('phone')" style="font-size: 14px;font-weight: 400;color: #AB54DB;">{{$t('rebate.send_auth_code')}}</div>
            <div v-else><span>{{phoneSeconds}}</span><span>{{$t('rebate.regain')}}</span></div>
          </div>
        </el-form-item>
      </el-form>
      <span style="display: block; font-size: 14px;color: #606266;margin-top: 30px;">重要提示</span>
      <div style="margin-top: 10px;">
        <div style="display: inline-block; background-color: #606266; width: 6px;height: 6px;vertical-align: middle;"></div>
        <span style="font-size: 14px;color: #606266; margin-left: 5px;vertical-align: middle;">提现账户为资金账户</span>
      </div>
      <div style="margin-top: 5px;">
        <div style="display: inline-block; background-color: #606266; width: 6px;height: 6px;vertical-align: middle;"></div>
        <span style="font-size: 14px;color: #606266; margin-left: 5px;vertical-align: middle;">提现手续费为提现金额的5%（最低5USDT），从提现金额里直接扣除</span>
      </div>
      <div style="margin-top: 5px;">
        <div style="display: inline-block; background-color: #606266; width: 6px;height: 6px;vertical-align: middle;"></div>
        <span style="font-size: 14px;color: #E12020; margin-left: 5px;vertical-align: middle;">{{$t('problem.Withdrawal_Instructions_tip')}}</span>
      </div>
      <div style="margin-top: 5px;">
        <div style="display: inline-block; background-color: #606266; width: 6px;height: 6px;vertical-align: middle;"></div>
        <span style="font-size: 14px;color: #E12020; margin-left: 5px;vertical-align: middle;">请确保您输入了正确的提币地址并且您选择的转账网络与地址相匹配</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%"  @click="handleWithdraw('confirm')">{{$t('rebate.confirm_withdraw')}}</el-button>
      </span>
    </el-dialog>
    <top_up ref="top_up" :userInfo="userInfo"></top_up>
    <dialog_tip ref="dialogTip" :content="$t('property.tip')"></dialog_tip>
    <el-dialog
        :visible.sync="topUpCardVisible"
        width="510px"
        title="点卡套餐"
        class="topUpDialog topUpcard"
        :close-on-click-modal="false"
        :before-close="closeDialogClear"
        height="304px">
      <div class="topUp">
        <div>
          <div :class="item.check?'card_bg_click':'card_bg'" style="" v-for="(item, index) in list" @click="selectCard(item, index)">
            <div style="width: 86%;height: 90px; display: inline-flex; justify-content: space-between; align-items: center; margin: 0 auto;cursor: pointer">
              <span class="card_assets_size" style="font-weight: 500;line-height: 136.02%;text-align: center;color: #464255;opacity: 0.8;">{{item.arriveAmount}}U</span>
              <div style="display: inline-block; background: #b4bccc; height: 50px; width: 1px;"></div>
              <div style="display: inline-block">
                <span class="card_assets_right" style="display: block;font-weight: 500;line-height: 136.02%;text-align: center;color: #464255;opacity: 0.8;">加赠<span style="margin-left: 1px" :style="{color: item.discountRate?'#FF5B5B':'#464255'}">{{ item.discountRate?(item.discountRate)*1 + '%':'0' }}</span></span>
                <span class="card_assets_right" style="display: block;font-weight: 500;line-height: 136.02%;text-align: center;color: #464255;opacity: 0.8;">售价{{item.amount}}{{isMobile?'U':'USDT'}}</span>
              </div>
            </div>
            <img v-if="item.check" style="width: 38px; height: 36px; position: absolute; right: -1px; bottom: -1px" src="../../assets/img/user/card_select.png">
          </div>
        </div>
        <div style="background: #ECECEC;border-radius: 6px;width: 100%; height: 1px;margin: 20px 0 15px"></div>
        <span style="display: block;font-weight: 500;font-size: 12px;line-height: 136.02%;color: #A3A3A3;">重要提示</span>
        <div style="margin-top: 5px; margin-left: 20px">
          <span style="display: block;font-weight: 500;font-size: 12px;line-height: 136.02%;color: #464255;">扣费系统优先扣除点卡账户，点卡不足将扣除燃料账户</span>
          <span style="display: block;font-weight: 500;font-size: 12px;line-height: 136.02%;color: #FF5B5B;margin-top: 5px">点卡余额不能提现，不能划转，非节点用户不能赠送。</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%" @click="buyCard()">{{ amountItem&&amountItem.amount?'支付 '+ amountItem.amount + ' USDT' :'购买' }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getUSDTRateByCode} from "../../server/user";
import {
  getDealRecord,
  getPropertyRecord, getRebateBonusRecord,
  getTopUpRecord,
  getWithDrawRecord,
  buyPointCard,
  getPointCardLog,
  transferMoney
} from "../../server/property";
import {checkEmail, checkWithdrawalAddress, checkEmailGeneral, checkTwoPlace, checkTwoPlaceGeneral} from "../../utils/validate";
import {getUserInfo, userWithdraw, userWithdrawNew} from "../../server/rebate";
import axios from "axios";
import {getAuthCode, selectPointCart, buyPointCardNew} from "../../server/auth";
import {topUp} from "../../mixin/mixin";
import {transferMixin} from "../../mixin/mixin";
import {dialogTipMixin} from "../../mixin/mixin";
import top_up from "../rebate/top_up";
import transfer from "./transfer";
import tooltip from "../common/tooltip";
import dialog_tip from "../common/dialog_tip";
export default {
  name: "Property",
  data(){
    return{
      isMini:'mini',
      userInfo:{},
      rate:6.4,
      //進度選擇的值
      activeScheduleName: '1',
      //記錄選擇的值
      activeRecordName: '2',
      scheduleList:[],
      scheduleListTotal:20,
      recordList:[],
      pointCardList: [],
      recordListTotal:20,

      topUpDialogVisible:false,  //充值对话框
      topUpInfo:{
        base58Address:'',
        qrCode:''
      },
      withdrawDialogVisible:false,  //提现对话框
      confirmWithdrawDialogVisible:false, //確認提現對話框
      withdrawForm:{
        money:'',
        address:'',
        phone_auth_code:''
      },
      withdrawFormRules:{
        money: [
          { required: true, message: this.$t('rebate.please_input_withdraw_money'), trigger: 'blur' },
          {validator:checkTwoPlace,trigger: 'blur'}],
        address: [{ required: true, message: this.$t('rebate.please_input_withdraw_address'), trigger: 'blur' },
          {validator:checkWithdrawalAddress,trigger: 'blur'}],
        phone_auth_code: [{ required: true, message: this.$t('rebate.please_input_email_auth_code'), trigger: 'blur' }],
      },
      //验证码
      isPhoneSent:true,
      phoneSeconds:60,
      topUpCardVisible: false,
      list: [
        {
          id: 1,
          amount: 50,
          arriveAmount: 50,
          giveAway: 0,
          check: false,
          url: '../../static/img/fuel_card/buy1.png',
          growth: '5',
          points: '50'
        },
        // {
        // 	id: 2,
        // 	amount: 50,
        // 	arriveAmount: 50,
        // 	check: false,
        // 	url: '../../static/img/fuel_card/buy2.png'
        // },
        {
          id: 3,
          amount: 100,
          arriveAmount: 108,
          giveAway: 8,
          check: false,
          url: '../../static/img/fuel_card/buy3.png',
          growth: '10',
          points: '100'
        },
        {
          id: 4,
          amount: 500,
          arriveAmount: 580,
          giveAway: 80,
          check: false,
          url: '../../static/img/fuel_card/buy4.png',
          growth: 50,
          points: '500'
        },
        {
          id: 5,
          amount: 1000,
          arriveAmount: 1240,
          giveAway: 240,
          check: false,
          url: '../../static/img/fuel_card/buy5.png',
          growth: '100',
          points: '1000'
        },
        // {
        // 	id: 6,
        // 	amount: 2000,
        // 	arriveAmount: 2600,
        // 	check: false,
        // 	url: '../../static/img/fuel_card/buy6.png'
        // },
        {
          id: 7,
          amount: 5000,
          arriveAmount: 6600,
          giveAway: 1600,
          check: false,
          url: '../../static/img/fuel_card/buy7.png',
          growth: '500',
          points: '5000'
        },
        {
          id: 8,
          amount: 10000,
          arriveAmount: 14000,
          giveAway: 4000,
          check: false,
          url: '../../static/img/fuel_card/buy8.png',
          growth: '1000',
          points: '1w'
        },
      ],
      totalAmount: 0,
      amountItem: {},
      isMobile: false
    }
  },
  mixins:[topUp,transferMixin,dialogTipMixin],
  components:{
    top_up,
    transfer,
    tooltip,
    dialog_tip
  },
  created() {
    this.changeScreenSize()
    this.getUSDTRateByCode()
    this.getScheduleRecord(1)
    this.getMoneyRecord(2)
    if (this.$util._isMobile()){
      if (this.$util._isMobile()[0] == 'iPad'){
        this.isMobile = false
        return;
      }
      this.isMobile = true;
    } else {
      this.isMobile = false
    }
  },
  mounted() {
    this.getUserInfo()
    this.selectPointCart()
  },
  methods:{
    async getUserInfo(){
      const res= await getUserInfo()
      // this.userInfo=JSON.parse(localStorage.getItem('userInfo'))
      this.userInfo=res.data
      localStorage.setItem('userInfo', JSON.stringify(res.data))
      this.fromMoney=this.userInfo.asset_balance
      this.toMoney=this.userInfo.deposit
      this.renderPropertyEcharts()
    },
    //匯率
    async getUSDTRateByCode() {
      const {data} = await getUSDTRateByCode()
      this.rate = data
    },
    selectPointCart(){
      selectPointCart().then(res=>{
        if(!res) return
        if(res.success) {
          this.list = res.data
          for (var i = 0; i < this.list.length; i++) {
            this.list[i].amount = this.list[i].card_point
            this.list[i].arriveAmount = this.list[i].give_card
            this.list[i].growth = this.list[i].growthAdd
            this.list[i].points = this.list[i].pointsAdd
            if(i==0) {
              this.list[i].check = true
              this.totalAmount+=this.list[i].amount
              this.amountItem = this.list[i]
            } else {
              this.list[i].check = false
            }
            switch (this.list[i].card_point){
              case 50:
                this.list[i].url = '../../static/img/fuel_card/buy1.png'
                break;
              case 100:
                this.list[i].url = '../../static/img/fuel_card/buy3.png'
                break;
              case 500:
                this.list[i].url = '../../static/img/fuel_card/buy4.png'
                break;
              case 1000:
                this.list[i].url = '../../static/img/fuel_card/buy5.png'
                break;
              case 2000:
                this.list[i].url = '../../static/img/fuel_card/buy6.png'
                break;
              case 5000:
                this.list[i].url = '../../static/img/fuel_card/buy7.png'
                break;
              case 10000:
                this.list[i].url = '../../static/img/fuel_card/buy8.png'
                break;
              default:
                break;
            }
          }
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    renderPropertyEcharts(){
      let propertyEcharts = this.$echarts.init(document.getElementById('propertyEcharts'));
      const option = {
        color: ['#5587EE', '#08D696', '#F3CB4E'],
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b}: {d}%",
          position: 'right'
        },
        series: [
          {
            name: this.$t('property.my_capital'),
            type: 'pie',
            radius: ['50%', '95%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'center',
              formatter:this.$t('property.capital_ratio'),
              color:'#72777E',
              fontSize: 16,
              fontWeight: 'bold'
            },
            // itemStyle:{
            //   normal:{
            //     label:{
            //       show: true,
            //       formatter: '{b} : {c} ({d}%)'
            //     },
            //     labelLine :{show:true}
            //   }
            // } ,
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: '16',
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
              show: false
            },
            data: [
              { value: this.userInfo.asset_balance==null ? 0 : this.userInfo.asset_balance, name: this.$t('property.capital_account') },
              { value: this.userInfo.deposit==null ? 0 : this.userInfo.deposit, name: this.$t('property.trading_account') },
              { value: this.userInfo.commission_balance==null ? 0 : this.userInfo.fuel_card, name: this.$t('property.rebate_account') }
            ]
          }
        ]
      }
      propertyEcharts.setOption(option)
      window.addEventListener('resize',()=> {
        propertyEcharts.resize()
      })
    },
    //獲取进度表格記錄
    async getScheduleRecord(type,pageNum,pageSize){
      if (type==1) {var res = await getTopUpRecord(pageNum, pageSize)}
      if (type==2) {var res = await getWithDrawRecord(pageNum, pageSize) }
      console.log(type);
      this.scheduleListTotal=res.data.totalRow
      this.scheduleList=res.data.list
    },
    //進度選擇
    handleChangeSchedule(tab,e){
      console.log(this.activeScheduleName);
      if (tab.name==1) this.getScheduleRecord(1)
      if (tab.name==2) this.getScheduleRecord(2)
    },
    //進度分頁
    handleScheduleCurrentChange(e){
      if (this.activeScheduleName==1) this.getScheduleRecord(1,e)
      if (this.activeScheduleName==2) this.getScheduleRecord(2,e)
    },

    //獲取記錄表格記錄
    async getMoneyRecord(type,pageNum,pageSize){
      if (type==1) {var res = await getPropertyRecord(pageNum, pageSize)}
      if (type==2) {var res = await getDealRecord(pageNum, pageSize) }
      // if (type==3) {var res = await getRebateBonusRecord(pageNum, pageSize) }
      if (type==3) {var res = await getPointCardLog(pageNum, pageSize) }
      this.recordListTotal=res.data.totalRow
      if (type == 3){
        this.pointCardList = res.data
        for (var i = 0; i < this.pointCardList.length; i++) {
          this.pointCardList[i].content = this.$t('property.rebate_account')+(this.pointCardList[i].card_amount + this.pointCardList[i].give_card_amount) + 'U' + this.$t('property.combo')
        }
      } else {
        this.recordList=res.data.list
        for (var i = 0; i < this.recordList.length; i++) {
          if (this.recordList[i].transaction_type){
            if(this.recordList[i].user_info.split('_')[0] == 'binance'){
              this.recordList[i].exchange = 'Binance'
            } else if(this.recordList[i].user_info.split('_')[0] == 'ok') {
              this.recordList[i].exchange = 'Okx'
            }
            if(this.recordList[i].transaction_type == 'future'){
              this.recordList[i].transactionType = this.$t('robot.grid_contract_strategy')
              this.recordList[i].coins = this.recordList[i].user_info.split('_')[3]
            } else if(this.recordList[i].transaction_type == 'spot'){
              if(this.recordList[i].user_info.split('_')[2] == 'bb'){
                this.recordList[i].transactionType = this.$t('robot.bb_trade')
                this.recordList[i].coins = this.recordList[i].user_info.split('_')[4]
              } else if(this.recordList[i].user_info.split('_')[2] == 'grid'){
                this.recordList[i].transactionType = this.$t('robot.grid_strategy')
                this.recordList[i].coins = this.recordList[i].user_info.split('_')[6]
              } else if(this.recordList[i].user_info.split('_')[2] == 'martin'){
                this.recordList[i].transactionType = this.$t('robot.martin_strategy')
                this.recordList[i].coins = this.recordList[i].user_info.split('_')[6]
              }
            }
          }
        }
      }
    },
    //記錄選擇
    handleChangeRecord(tab,e){
      this.getMoneyRecord(tab.name)
    },
    //記錄分頁
    handleRecordCurrentChange(e){
      this.getMoneyRecord(this.activeRecordName,e)
    },

    //  发送数字验证码
    async sendAuthCode(kind){
      if (this.userInfo.username===''||this.userInfo.username===null) return this.$message.warning(this.$t('rebate.please_bind_email'))
      if (kind==='phone'){
        this.isPhoneSent=false
        const timer=setInterval(()=>{
          this.phoneSeconds--
          if (this.phoneSeconds===0){
            this.phoneSeconds=60
            this.isPhoneSent=true
            clearInterval(timer)
          }
        },1000)
        const res=await getAuthCode(4,1,this.userInfo.username)
        if (!res.success) return this.$message.warning(res.message)
        if (res.success) this.$message.success(this.$t('rebate.auth_code_send_email'))
      }
    },

    showTixian(){
      this.withdrawDialogVisible=true
      this.withdrawForm.money = ''
    },

    //  提现
    async handleWithdraw(e){
      if (e!='confirm') {
        if (this.withdrawForm.money=='') return this.$message.warning(this.$t('rebate.withdraw_money_unable_empty'))
        if (!checkTwoPlaceGeneral(this.withdrawForm.money)) return this.$message.warning(this.$t('rebate.point_two'))
        if (this.withdrawForm.money < 50) return this.$message.warning(this.$t('validate.Minimum_cash_out_amount'))
        if (this.withdrawForm.address=='') return this.$message.warning(this.$t('rebate.withdraw_address_unable_empty'))
        if (!this.checkEmailGeneral(this.withdrawForm.address)) return this.$message.error(this.$t('rebate.withdrawal_address_incorrect'))
        return this.confirmWithdrawDialogVisible = true
      }
      this.$refs.withdrawFormRef.validate( async valid=>{
        if (!valid) return this.$message.error(this.$t('rebate.withdraw_info_input_error'))
        const {money,address,phone_auth_code}=this.withdrawForm
        const res=await userWithdrawNew(address,money,phone_auth_code)
        if (!res.success) return this.$message.error(res.message)
        this.$message.success(this.$t('rebate.withdraw_apply_sent'))
        this.withdrawDialogVisible=false
        this.confirmWithdrawDialogVisible=false
        this.getUserInfo()
      })
    },
    //点击全部按钮,提现金额全部填入
    handleMoneyAll(){
      this.withdrawForm.money=this.userInfo.asset_balance
    },

    handleCopyAddress(){
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', this.topUpInfo.base58Address);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('rebate.copy_success'))
      }
      document.body.removeChild(input);
    },

    changeScreenSize(){
      window.addEventListener('resize',()=>{
        const bodyWidth=document.body.clientWidth
        if (bodyWidth>=1200) {
          this.isMini='medium'
          return
        }
        this.isMini="mini"
      })
      const bodyWidth=document.body.clientWidth
      if (bodyWidth>=1200) return this.isMini='medium'
      this.isMini='mini'
    },

    checkinput(value, name, num) {       // 通过正则过滤小数点后两位
      let inputValue =
          ("" + value) // 第一步：转成字符串
              .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
              // .replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"") // 第二步：把不是数字，不是小数点的过滤掉
              .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
              .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
              .match(/^\d*(\.?\d{0,2})/g)[0] || "" // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到10位小数
      if (parseInt(inputValue) > 9007199254740991) {
        inputValue = 9007199254740991
      }
      this.withdrawForm.money = inputValue
    },

    checkEmailGeneral(value){
      if (value.substr(0,1) != 'T' || value.length>34 || value.length<34) return false
      return true
    },

    selectCard(item, index){
      for (var i = 0; i < this.list.length; i++) {
        if(index == i){
          if(this.list[i].check == true){
            this.list[i].check = false
          } else {
            this.list[i].check = true
          }
        } else {
          this.list[i].check = false
        }
        this.totalAmount = 0
      }

      for (var i = 0; i < this.list.length; i++) {
        if(this.list[i].check == true){
          this.totalAmount+=this.list[i].amount
          this.amountItem = this.list[i]
        }
      }
    },

    buyCard(){
      var amount = 0
      var giveCard = 0
      for (var i = 0; i < this.list.length; i++) {
        if(this.list[i].check){
          amount = this.list[i].amount
          giveCard = this.list[i].give_card
        }
      }
      if(!amount || amount == 0){
        this.$message.warning('请选择要购买的点卡')
        return
      }
      if (this.userInfo.asset_balance < amount){
        this.$message.warning('资金账户不足，请先充值！')
        return
      }

      buyPointCardNew(amount, giveCard).then(res=>{
        if(!res) return
        if(res.success){
          this.$message.warning('购买成功')
            var timer = setTimeout(()=>{
              this.getUserInfo()
              this.topUpCardVisible = false
              clearTimeout(timer)
          },1500)
        } else {
          this.$message.error(res.message)
        }
      })

      // buyPointCard(amount).then(res=>{
      //   if(!res) return
      //   if(res.success){
      //     this.$message.warning('购买成功')
      //     var timer = setTimeout(()=>{
      //       this.getUserInfo()
      //       this.topUpCardVisible = false
      //       clearTimeout(timer)
      //     },1500)
      //   } else {
      //     this.$message.error(res.message)
      //   }
      // })
    },
    closeDialogClear(){
      this.amountItem = {}
      for (var i = 0; i < this.list.length; i++) {
        this.list[i].check = false
      }
      this.topUpCardVisible = false
    }

  }
}
</script>

<style scoped>
.title{
  font-size: 16px;
  font-weight: 500;
  color: #000613;
}
.sub-title{
  font-size: 16px;
  font-weight: 500;
  color: #72777E;
  margin: 13px 0 41px 0;
}
.sub-title>span:first-child{
  font-size: 24px;
  font-weight: bold;
  color: #000613;
}
.property{
  display: flex;
}
.property .echarts{
  flex: 0.9;
  min-width: 222px;
  height: 200px;
}
.property>div:nth-child(n+2){
  flex: 1;
}
.property>div:nth-child(1){
  border-right: 1px solid #EAECEF;
  box-sizing: border-box;
}
.property>div:nth-child(n+2){
  background: #F7F9FC;
  margin-left: 2%;
  padding: 25px 24px;
}
.property>div:nth-child(n+2)>div:first-child>span:first-child{
  width: 6px;
  height: 6px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
.property>div:nth-child(n+2)>div:first-child>span:nth-child(2){
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: #72777E;
  vertical-align: middle;
}
.property>div:nth-child(n+2)>div:nth-child(2){
  font-size: 22px;
  font-weight: bold;
  color: #000613;
  margin: 16px 0 8px 0;
}
.property>div:nth-child(n+2)>div:nth-child(3){
  font-size: 16px;
  font-weight: 500;
  color: #72777E;
  margin-bottom: 24px;
}
/*第一個icon的按鈕*/
.property>div:nth-child(n+2)>div:nth-child(4){
  display: flex;
}
.property>div:nth-child(n+2)>div:nth-child(4)>span{
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  color: #AB54DB;
  line-height: 36px;
  height: 36px;
  border-radius: 2px;
  text-align: center;
  border: 1px solid #AB54DB;
  cursor: pointer;
}
.property>div:nth-child(n+2)>div:nth-child(4)>span:hover{
  background: #AB54DB;
  color: #FFFFFF;
}
.property>div:nth-child(2)>div:nth-child(4)>span:nth-child(2),.property>div:nth-child(3)>div:nth-child(4)>span:nth-child(2){
  margin-left:20px;
}

.card_bg {
  display: inline-flex;
  width: 48%;
  background: rgba(223, 223, 223, 0.6);
  border-radius: 10px;
}

.card_bg:nth-child(2n){
  margin-left: 3%;
}

.card_bg:nth-child(n+2){
  margin-top: 20px;
}

.card_bg_click{
  display: inline-flex;
  width: 47.3%;
  height: 86px;
  background: #FFFFFF;
  border-radius: 10px;
  border: rgba(171, 84, 219, 1) 2px solid;
  position: relative;
}

.card_bg_click:nth-child(2n){
  margin-left: 3%;
}

.card_bg_click:nth-child(n+2){
  margin-top: 20px;
}

.card_assets_size{
  font-size: 20px;
}

.card_assets_right{
  font-size: 14px;
}

@media screen and (max-width: 1300px){
  .property{
    display: flex;
    flex-wrap: wrap;
  }
  .property .echarts{
    flex: 1;
    min-width: 330px;
    height: 200px;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 24px;
  }
  .property>div:nth-child(n+2){
    flex: 1;
    min-width: 330px;
    box-sizing: border-box;
    margin: 10px 0;
    display: inline-block;
  }
  .property>div:nth-child(n+2){
    margin-left: 0;
  }
  .property>div:nth-child(2n){
    margin-left:3% ;
  }
  .property>div:nth-child(1){
    border-right: unset;
  }
}

.sm{
  display: none;
}
/*手機端的樣式*/

.sm .btns{
  display: flex;
  margin-top: 24px;
}
.sm .btns>span{
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  color: #AB54DB;
  line-height: 36px;
  height: 36px;
  border-radius: 2px;
  text-align: center;
  border: 1px solid #AB54DB;
  cursor: pointer;
}
.sm .btns>span:hover{
  background: #AB54DB;
  color: #FFFFFF;
}
.sm .btns>span:nth-child(2){
  margin: 0 16px;
}
/*sm-icons*/
.sm-icons>div:first-child>span:first-child{
  width: 6px;
  height: 6px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}
.sm-icons>div:first-child>span:nth-child(2){
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: #72777E;
  vertical-align: middle;
}
.sm-icons>div:nth-child(2){
  font-size: 22px;
  font-weight: bold;
  color: #000613;
  margin: 16px 0 8px 0;
}
.sm-icons>div:nth-child(3){
  font-size: 16px;
  font-weight: 500;
  color: #72777E;
}

@media screen and (max-width: 750px){
  .lg{
    display: none !important;
  }
  .sm{
    display: block;
  }
  .property>div:nth-child(n+2){
    margin:10px 0;
  }
  /*.property{*/
  /*  justify-content: center;*/
  /*}*/
  /*.property >div:nth-child(n+2){*/
  /*  width: 300px;*/
  /*  flex: unset;*/
  /*}*/
  .card_assets_size{
    font-size: 14px;
  }
  .card_assets_right{
    font-size: 12px;
  }

}



/*表格*/
.el-pagination{
  margin-bottom:  24px !important;
  padding: 0;
}
.el-table{
  margin-bottom: 24px
}


@media screen and (max-width: 800px){
  .el-pagination{
    margin: 14px 0 !important;
    padding: 0;
  }
  /deep/ .el-pagination ul li{
    min-width: 30px;
  }
  /*.select-type{*/
  /*  display: block !important;*/
  /*}*/
  /*.select-type>div:first-child,.select-type>div:nth-child(3){*/
  /*  display: flex;*/
  /*  align-items: center;*/
  /*  flex-wrap: wrap;*/
  /*}*/
  /*.select-type>div>div:first-child{*/
  /*  margin-right: 10px;*/
  /*}*/
  /*.select-type>div:nth-child(2){*/
  /*  text-align: right;*/
  /*  height: 30px;*/
  /*  !*line-height: 30px;*!*/
  /*}*/
  /*.select-type>div:nth-child(2)>i{*/
  /*  transform: rotate(0) !important;*/
  /*}*/
  /*.select-type .remain{*/
  /*  width: 100%;*/
  /*  margin-left: 26px;*/
  /*}*/
  /*.transfer>div:nth-child(2){*/
  /*  margin-bottom: 10px;*/
  /*}*/
}
.select-type .remain{
  margin-top: 4px;
}


/*充值的对话框*/
/*/deep/ .topUpDialog .el-dialog__body {*/
/*  padding:15px 26px !important;*/
/*}*/

>>>.topUpDialog .el-dialog__body {
  padding:0px 26px 15px !important;
}
>>>.topUpDialog .el-dialog__header {
  padding-bottom: 0;
}
.topUp>div:first-child{
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(2){
  margin: 8px 0 24px 0px;
}
.topUp>div:nth-child(3){
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(4) img{
  width: 136px;
  height: 136px;
  display: inline-block;
  margin: 8px 0 16px 0;
}
.topUp>div:nth-child(5){
  padding: 11px 0 24px 0;
  border-top: 1px solid #EAECEF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topUp>div:nth-child(5)>span{
  font-size: 12px;
  color: #1E2329;
}
.topUp>div:nth-child(5)>img{
  display: inline-block;
}
.topUp>div:nth-child(6){
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(7)>ul{
  margin: 8px 0 0 0 ;
  padding-left: 20px;
}
.topUp>div:nth-child(7)>ul>li{
  font-size: 14px;
  color: #1E2329;
  line-height: 25px;
}
.topUp>div:nth-child(7)>ul>li:nth-child(2){
  color:red;
}
#sm-tip{
  display: none;
}
@media screen  and (max-width: 900px){
  .topUp>div:nth-child(4) img{
    width: 100px;
    height: 100px;
    margin: 8px 0 8px 0;
  }
  .topUp>div:nth-child(5){
    padding: 10px 0 11px 0;
  }
  /deep/ .topUpDialog .el-dialog__body {
    padding:5px 20px !important;
  }
  /deep/ .topUpDialog .el-form-item {
    margin-bottom: 8px;
  }
  #lg-tip{
    display: none;
  }
  #sm-tip{
    display: inline-block;
  }
}

/*验证码*/
.auth-code{
  position: absolute;
  top:42px;
  right: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #8E9298;
}
.auth-code>div>span:first-child{
  font-size: 14px;
  font-weight: 400;
  color: #AB54DB;
  margin-right: 6px;
}
.top-up-btn{
  background: #AB54DB;
  color: #FFFFFF !important;
}
.top-up-btn:hover{
  opacity: 0.8;
}


</style>
